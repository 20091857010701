var $ = require("jquery");
export function navSideNavigation() {
    $(document).ready(function () {
        $(".side-navigation .btn-collapse").click(function () {
            const currentButton = $(this);

            if (!currentButton.parent().hasClass("expanded")) {
                currentButton.parent().toggleClass("expanded");
                currentButton.next().slideDown("fast");
            } else {
                currentButton.next().slideUp("fast", function () {
                    currentButton.parent().toggleClass("expanded");
                });
            }
        });

        $(".side-navigation .btn-collapse-mobile").click(function () {
            const currentButton = $(".side-navigation .list-mobile");
            const selectButton = $(".side-navigation .select-mobile-link"); 

            if (!currentButton.hasClass("expanded")) {
                currentButton.toggleClass("expanded");
                currentButton.slideDown("fast");
                selectButton.addClass("active");
            } else {
                currentButton.slideUp("fast", function () {
                    currentButton.toggleClass("expanded");
                    selectButton.removeClass("active");
                });
            }
        });

        $(".side-navigation .close-list-mobile").click(function () {
            const currentButton = $(".side-navigation .list-mobile");
            const selectButton = $(".side-navigation .select-mobile-link"); 

            currentButton.slideUp("fast", function () {
                currentButton.toggleClass("expanded");
                selectButton.removeClass("active");
            });
        });

    });
}

