var $ = require("jquery");
export function GoogleTranslateWidget() {
    window.onchange = function () {
        function handleLanguageSelect() {
            var languageCode = document.getElementsByTagName("data-lang-code");
            var previousPage = document.referrer;
            var currentPath = window.location.origin;

            var newURL = '';

            if (previousPage !== '' && previousPage != currentPath) {
                newURL = new URL(previousPage);
            }

            var finalPath;
            const modifiedPath = currentPath.replace(/-/g, '--').replace(/\./g, '-');

            if (newURL === '') {
                finalPath = `${modifiedPath}.translate.goog/?_x_tr_sl=en&_x_tr_tl=${languageCode}&_x_tr_hl=en`;
            } else {
                finalPath = `${modifiedPath}.translate.goog${newURL.pathname}?_x_tr_sl=en&_x_tr_tl=${languageCode}&_x_tr_hl=en`;
            }
            sessionStorage.setItem('translatedLink', finalPath);
            window.location.href = finalPath;
        }

        const iframeTranslate = document.getElementById(":1.container");
        if (iframeTranslate !== null) {
            iframeTranslate.setAttribute("alt", "Skip Translate");
        }

        var languageSelect = document.getElementById("languageSelect");

        if (languageSelect) {
            languageSelect.selectedIndex = 0;
            languageSelect.addEventListener('change', handleLanguageSelect);
        }

        var savedLink = sessionStorage.getItem('translatedLink');

        if (savedLink !== null) {
            sessionStorage.removeItem('translatedLink');
        }


    };
}