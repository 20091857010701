export function featuredEventsCarouselWidget() {
    $(document).ready(function () {
        const buttonHeight = 47;

        function setActiveElement(featureItem) {
            featureItem.addClass("active");
            adjustFeatureItemHeight(featureItem, true);
        };

        function removeActiveElement(featureItems) {
            featureItems.each(function (index, element) {
                if ($(element).hasClass("active")) {
                    $(element).removeClass("active");
                    adjustFeatureItemHeight($(element), false);
                }
            });
        };

        function adjustFeatureItemHeight(featureItemElement, increase) {
            const featureItemText = featureItemElement.find(".feature-item-text");
            if ($.isEmptyObject(featureItemText)) {
                return;
            }
            
            featureItemText.height(increase ? (featureItemText.height() + buttonHeight) : (featureItemText.height() - buttonHeight));
        };

        function calculateFeatureItemHeight(featureItemElement) {
            const featureItemText = featureItemElement.find(".feature-item-text");
            if ($.isEmptyObject(featureItemText)) {
                return;
            }

            const height = featureItemText.height();
            if (featureItemElement.hasClass("active")) {
                featureItemText.height(height);
            } else {
                const featureItemTextButton = featureItemText.find(".feature-item-text-button");
                if ($.isEmptyObject(featureItemTextButton)) {
                    return;
                }

                if (!featureItemTextButton.is(":visible")) {
                    featureItemText.height(height);
                    featureItemTextButton.show();
                    featureItemTextButton.removeAttr("style");
                } else {
                    featureItemText.height(height - buttonHeight);
                }
            }
        };

        function addSwiffyClasses(carouselWidget, isLg) {
            if (!carouselWidget.parent().hasClass("swiffy-slider")) {
                carouselWidget.parent().addClass("swiffy-slider");
                carouselWidget.parent().addClass("slider-nav-noloop");
            }
            
            if (isLg) {
                if (!carouselWidget.parent().hasClass("slider-item-show")) {
                    carouselWidget.parent().addClass("slider-item-show");
                }
            } else {
                if (carouselWidget.parent().hasClass("slider-item-show")) {
                    carouselWidget.parent().removeClass("slider-item-show");
                }
            }
            
            if (!carouselWidget.parent().hasClass("slider-item-reveal")) {
                carouselWidget.parent().addClass("slider-item-reveal");
            }

            if (!carouselWidget.parent().hasClass("slider-nav-visible")) {
                carouselWidget.parent().addClass("slider-nav-visible");
            }

            if (!carouselWidget.parent().hasClass("slider-nav-round")) {
                carouselWidget.parent().addClass("slider-nav-round");
            }

            if (!carouselWidget.parent().hasClass("slider-nav-autohide")) {
                carouselWidget.parent().addClass("slider-nav-autohide");
            }

            if (!carouselWidget.hasClass("slider-container")) {
                carouselWidget.addClass("slider-container");
            }

            if (carouselWidget.hasClass("featured-items-carousel")) {
                carouselWidget.removeClass("featured-items-carousel");
            }

            const featureItems = carouselWidget.find(".feature-item");

            featureItems.each(function (index, element) {
                const featureItemElement = $(element);

                const featureItemText = featureItemElement.find(".feature-item-text");
                if ($.isEmptyObject(featureItemText)) {
                    return;
                }

                const featureItemTextButton = featureItemText.find(".feature-item-text-button");
                if ($.isEmptyObject(featureItemTextButton)) {
                    return;
                }

                if (!featureItemTextButton.is(":visible")) {
                    featureItemTextButton.removeAttr("style");
                }
            });
        }

        function removeSwiffyClasses(carouselWidget) {
            if (carouselWidget.parent().hasClass("swiffy-slider")) {
                carouselWidget.parent().removeClass("swiffy-slider");
                carouselWidget.parent().removeClass("slider-nav-noloop");
            }

            if (carouselWidget.parent().hasClass("slider-item-show")) {
                carouselWidget.parent().removeClass("slider-item-show");
            }

            if (carouselWidget.parent().hasClass("slider-item-reveal")) {
                carouselWidget.parent().removeClass("slider-item-reveal");
            }

            if (carouselWidget.parent().hasClass("slider-nav-visible")) {
                carouselWidget.parent().removeClass("slider-nav-visible");
            }

            if (carouselWidget.parent().hasClass("slider-nav-round")) {
                carouselWidget.parent().removeClass("slider-nav-round");
            }

            if (carouselWidget.parent().hasClass("slider-nav-autohide")) {
                carouselWidget.parent().removeClass("slider-nav-autohide");
            }

            if (carouselWidget.hasClass("slider-container")) {
                carouselWidget.removeClass("slider-container");
            }

            if (!carouselWidget.hasClass("featured-items-carousel")) {
                carouselWidget.addClass("featured-items-carousel");
            }
        }

        function bindHoverAndFocus(carouselWidget) {
            if (!carouselWidget.hasClass("featured-items-carousel")) {
                carouselWidget.addClass("featured-items-carousel");
            }

            const featureItems = carouselWidget.find(".feature-item");

            if ($.isEmptyObject(featureItems)) {
                return;
            }

            if (!$(featureItems).hasClass("active")) {
                $(featureItems[0]).addClass("active");
            }

            featureItems.on("mouseenter", function () {
                removeActiveElement(featureItems);
                setActiveElement($(this));
            });

            featureItems.each(function (index, element) {
                const featureItem = $(element);

                const allAnchors = featureItem.find("a");
                if ($.isEmptyObject(allAnchors)) {
                    return;
                }

                allAnchors.on("focus", function () {
                    if (!featureItem.hasClass("active")) {
                        removeActiveElement(featureItems);
                        setActiveElement(featureItem);
                    }
                });

                calculateFeatureItemHeight(featureItem);
            });
        }

        function unbindHoverAndFocus(carouselWidget) {
            const featureItems = carouselWidget.find(".feature-item");

            if ($.isEmptyObject(featureItems)) {
                return;
            }

            if ($(featureItems).hasClass("active")) {
                $(featureItems).removeClass("active");
            }

            featureItems.off("mouseenter");

            featureItems.each(function (index, element) {
                const featureItem = $(element);

                const allAnchors = featureItem.find("a");
                if ($.isEmptyObject(allAnchors)) {
                    return;
                }

                allAnchors.off("focus");
            });
        }

        var featuredItemsCarouselWidget = $(".featured-events-container");

        if ($.isEmptyObject(featuredItemsCarouselWidget)) {
            return;
        }

        featuredItemsCarouselWidget.each(function (index, element) {
            const carouselWidget = $(element);
            if (viewport.is("lg")) {
                unbindHoverAndFocus(carouselWidget);
                addSwiffyClasses(carouselWidget, true);
                window.swiffyslider.init();
            } else if (viewport.is("<=md")) {
                unbindHoverAndFocus(carouselWidget);
                addSwiffyClasses(carouselWidget);
                window.swiffyslider.init();
            } else {
                removeSwiffyClasses(carouselWidget);
                bindHoverAndFocus(carouselWidget);
            }
        });

        $(window).resize(function () {
            featuredItemsCarouselWidget.each(function (index, element) {
                const carouselWidget = $(element);
                carouselWidget.find(".feature-item-text").css("height", "");

                if (viewport.is("lg")) {
                    unbindHoverAndFocus(carouselWidget);
                    addSwiffyClasses(carouselWidget, true);
                    window.swiffyslider.init();
                } else if (viewport.is("<=md")) {
                    unbindHoverAndFocus(carouselWidget);
                    addSwiffyClasses(carouselWidget);
                    window.swiffyslider.init();
                } else {
                    removeSwiffyClasses(carouselWidget);
                    bindHoverAndFocus(carouselWidget);
                }
            });
        });
    });
}