export function carouselWidget() {
    $(document).ready(function () {
        const splideElements = $(".splide");
        if (splideElements && splideElements.length > 0) {
            for (var i = 0; i < splideElements.length; i++) {
                var splideElement = splideElements[i];
                if ($(splideElement).hasClass("carousel-full")) {
                    const splide = new Splide(splideElement);
                    splide.mount();
                } else {
                    const splide = new Splide(splideElement, {
                        type: "loop",
                        padding: "6rem"
                    });
                    splide.mount();
                }
            }
        }
    });
}