import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function eventsReducer(state = initialState.eventsListing, action) {

    switch (action.type) {
        case types.EVENTS_SWITCH_VIEW_MODE:
            return {
                ...state,
                viewMode: action.viewMode
            };

        case types.IS_LOADING_LIST_EVENTS:
            return {
                ...state,
                listEvents: {
                    ...state.listEvents,
                    isLoading: action.isLoadingList
                }

            };

        case types.SET_LIST_EVENTS_COUNT_SEARCH:
            return {
                ...state,
                listEvents: {
                    ...state.listEvents,
                    count: action.count
                }

            };

        case types.SET_LIST_EVENTS_ITEMS:
            return {
                ...state,
                listEvents: {
                    ...state.listEvents,
                    isLoading: action.isLoadingList,
                    items: action.items,
                    totalItems: action.totalItems
                }
            };

        case types.LOAD_MORE_LIST_EVENTS_ITEMS:
            return {
                ...state,
                listEvents: {
                    ...state.listEvents,
                    isLoading: action.isLoadingList,
                    items: [...state.listEvents.items,  ...action.items]
                }
            };

        case types.SET_LIST_EVENTS_FILTERS_DATES:
            return {
                ...state,
                listEvents: {
                    ...state.listEvents,
                    filters: {
                        ...state.listEvents.filters,
                        startDate: action.startDate,
                        endDate: action.endDate,
                    }
                }
            };

        case types.SET_LIST_EVENTS_FILTERS_SEARCH_KEYWORDS:
            return {
                ...state,
                listEvents: {
                    ...state.listEvents,
                    filters: {
                        ...state.listEvents.filters,
                        searchKeywords: action.searchKeywords,
                    }
                }
            };

        default:
            return state;
    }
}

