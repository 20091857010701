var $ = require("jquery");
export function accordionBylawsWidget() {
    $(document).ready(function () {
        const accordionSections = $(".accordion-bylaws");
        if (accordionSections && accordionSections.length > 0) {
            accordionSections.each(function () {
                const accordionId = $(this).attr("id");
                if (accordionId) {
                    const collapseElementList =
                        [].slice.call(document.getElementById(accordionId).querySelectorAll(".accordion-collapse"));
                    if (collapseElementList && collapseElementList.length > 0) {
                        collapseElementList.map(function(collapseEl) {
                            collapseEl.addEventListener("show.bs.collapse",
                                function() {
                                    $(collapseEl).parent().addClass("open");
                                });
                            collapseEl.addEventListener("hide.bs.collapse",
                                function() {
                                    $(collapseEl).parent().removeClass("open");
                                });
                        });
                    }
                }
            });
            
            $(".accordion-button").click(function() {
                if ($(".accordion-item").length === $(".accordion-item.open").length) {
                    $(".toggle-bylaw-accordion").addClass("hide-all").removeClass("show-all");
                    $(".toggle-bylaw-accordion").text("Hide all");
                } else {
                    $(".toggle-bylaw-accordion").addClass("show-all").removeClass("hide-all");
                    $(".toggle-bylaw-accordion").text("Show all");
                }
            });

            $(".toggle-bylaw-accordion").on("click",function() {
                    if ($(".accordion-button").hasClass("collapsed")) {
                        $(".accordion-header").parent(".accordion-item").addClass("open");
                        $(".accordion-button").removeClass("collapsed");
                        $(".accordion-collapse.collapse").addClass("show");
                        $(".toggle-bylaw-accordion").addClass("hide-all").removeClass("show-all");
                        $(".toggle-bylaw-accordion").text("Hide all");
                    } else {
                        $(".accordion-header").parent(".accordion-item").removeClass("open");
                        $(".accordion-button").addClass("collapsed");
                        $(".accordion-collapse.collapse").removeClass("show");
                        $(".toggle-bylaw-accordion").addClass("show-all").removeClass("hide-all");
                        $(".toggle-bylaw-accordion").text("Show all");
                    }
            });
        }
    });
}