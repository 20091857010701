var $ = require("jquery");

export function photoSwipe() {
    $(document).ready(function() {
        var photoSwipeItems = Object.create(null);
        var $productGallery = undefined;

        function initPhotoSwipe() {
            if (!jQuery(".pswp:first").length) {
                let myGalleryHtmlEl = "";

                myGalleryHtmlEl += '<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">';

                myGalleryHtmlEl += '    <div class="pswp__bg"></div>';

                myGalleryHtmlEl += '    <div class="pswp__scroll-wrap">';
                myGalleryHtmlEl += '        <div class="pswp__container">';

                myGalleryHtmlEl += '            <div class="pswp__item"></div>';
                myGalleryHtmlEl += '            <div class="pswp__item"></div>';
                myGalleryHtmlEl += '            <div class="pswp__item"></div>';
                myGalleryHtmlEl += '        </div>';

                myGalleryHtmlEl += '        <div class="pswp__ui pswp__ui--hidden">';
                myGalleryHtmlEl += '            <div class="pswp__top-bar">';
                myGalleryHtmlEl += '                <div class="pswp__counter"></div>';
                myGalleryHtmlEl += '                <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>';
                myGalleryHtmlEl += '                <button class="pswp__button pswp__button--share" title="Share"></button>';
                myGalleryHtmlEl += '                <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>';
                myGalleryHtmlEl += '                <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>';

                myGalleryHtmlEl += '                <div class="pswp__preloader">';
                myGalleryHtmlEl += '                    <div class="pswp__preloader__icn">';
                myGalleryHtmlEl += '                        <div class="pswp__preloader__cut">';
                myGalleryHtmlEl += '                            <div class="pswp__preloader__donut"></div>';
                myGalleryHtmlEl += '                        </div>';
                myGalleryHtmlEl += '                    </div>';
                myGalleryHtmlEl += '                </div>';
                myGalleryHtmlEl += '            </div>';

                myGalleryHtmlEl += '            <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">';
                myGalleryHtmlEl += '                <div class="pswp__share-tooltip"></div>';
                myGalleryHtmlEl += '            </div>';

                myGalleryHtmlEl += '            <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)"></button>';
                myGalleryHtmlEl += '            <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"></button>';

                myGalleryHtmlEl += '            <div class="pswp__caption">';
                myGalleryHtmlEl += '               <div class="pswp__caption__center"></div>';
                myGalleryHtmlEl += '            </div>';
                myGalleryHtmlEl += '        </div>';
                myGalleryHtmlEl += '    </div>';
                myGalleryHtmlEl += '</div>';

                jQuery('body').append(myGalleryHtmlEl);
            }
        }

        function openPhotoSwipe(galleryId, customindex) {
            const pswpElement = document.querySelectorAll('.pswp')[0];
            const options = {
                index: customindex,
                // Share buttons
                //
                // Available variables for URL:
                // {{url}}             - url to current page
                // {{text}}            - title
                // {{image_url}}       - encoded image url
                // {{raw_image_url}}   - raw image url
                shareButtons: [
                    { id: 'facebook', label: 'Share on Facebook', url: 'https://www.facebook.com/sharer/sharer.php?u={{url}}' },
                    { id: 'twitter', label: 'Tweet', url: 'https://twitter.com/intent/tweet?text={{text}}&url={{url}}' },
                    //{ id: 'pinterest', label: 'Pin it', url: 'http://www.pinterest.com/pin/create/button/?url={{url}}&media={{image_url}}&description={{text}}' },
                    { id: 'download', label: 'Download image', url: '{{raw_image_url}}', download: true }
                ],
                // define gallery index (for URL)
                galleryUID: galleryId,
                showHideOpacity: true,
                getThumbBoundsFn: function (index) {
                    // See Options -> getThumbBoundsFn section of documentation for more info

                    var thumbnail = photoSwipeItems[galleryId][index].el;
                    if (!thumbnail.is("img")) {
                        thumbnail = $productGallery.find(".gallery-img-data.last");
                    }
                    thumbnail = thumbnail && thumbnail[0];
                    if (!thumbnail) return { x: 0, y: 0, w: 0 };
                    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
                    const rect = thumbnail.getBoundingClientRect();
                    return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
                },
                closeElClasses: ['item', 'caption', 'zoom-wrap', 'ui', 'top-bar'],
            };
            const gallery = new window.PhotoSwipe(pswpElement, window.PhotoSwipeUI_Default, photoSwipeItems[galleryId], options);
            gallery.listen('close', removeVideo);
            gallery.listen('afterChange', onAfterChange.bind(gallery));
            gallery.listen('destroy', onDestroy);
            gallery.init();

        };

        function onDestroy() {
            //console.log('onDestroy..');

            $(this.currItem.el).focus();

            setTimeout(function () {
                // stop the video
                removeVideo();
                
                // clearh url hash
                history.pushState("", document.title, window.location.pathname
                    + window.location.search);
                history.back();
            }, 200);
        }



        function onAfterChange() {

            const that = this;
            var currentIndex = that.getCurrentIndex();

            $("iframe.pswp__video").each(function () {
                const $this = $(this);

                if ($this.data('order') !== currentIndex + 1) {
                    $this.attr("src", "");
                } else {
                    $this.attr("src", $this.data("src"));
                }
            });
        }

        function removeVideo() {
            $("iframe.pswp__video").remove();
        }

        function init() {

            if (jQuery(".widget-gallery, .photomosaic").length) {
                initPhotoSwipe();
                jQuery(".widget-gallery, .photomosaic").each(function (galleryIndex, galleryEl) {
                    $productGallery = jQuery(galleryEl);
                    var galleryId = $productGallery.attr('id');

                    var len = 0;
                    var orderedItems = Object.create(null);
                    $productGallery.find(".gallery-img-data").each(function (gItemIndex, el) {
                        var $gEl = jQuery(el);
                        var $imageBlock = undefined;
                        if ($gEl.is(":not([type=hidden])")) {
                            $imageBlock = $gEl.closest(".image-block");
                            $imageBlock.click(function (e) {
                                //console.log('gallerycxx galleryId ' + galleryId, jQuery(e.currentTarget).data("order"));

                                var _id = jQuery(e.currentTarget).closest('.widget-gallery, .photomosaic').attr('id');

                                openPhotoSwipe(_id, jQuery(e.currentTarget).data("order") - 1); // index from the parent callback
                            });
                        }

                        $gEl.keypress(function (ev) {
                            if (ev.keyCode === 13) {
                                ev.preventDefault();
                                var _id = jQuery(ev.currentTarget).closest('.widget-gallery, .photomosaic').attr('id');
                                openPhotoSwipe(_id, jQuery(ev.currentTarget).data("order") - 1);
                            }
                        })

                        var src = $gEl.data("imgurl");
                        len++;
                        orderedItems[$gEl.data("order")] =
                            $imageBlock && $imageBlock.data("video") ?
                                {
                                    el: $gEl,
                                    html: $imageBlock.data("video")
                                }
                                :
                                {
                                    el: $gEl,
                                    src: src,
                                    //   msrc: src,
                                    w: $gEl && ($gEl.data("width") || $gEl[0].naturalWidth),
                                    h: $gEl && ($gEl.data("height") || $gEl[0].naturalHeight),
                                    title: $gEl.data("caption")
                                };

                    });
                    photoSwipeItems[galleryId] = [];
                    for (var newInd = 0; newInd <= len; newInd++) {
                        var pItem = orderedItems[newInd];
                        if (!pItem) continue;
                        photoSwipeItems[galleryId].push(pItem);
                    }

                    jQuery('.see-gallery', jQuery(this)).click(function () {
                        openPhotoSwipe(galleryId, 5); // index from the parent callback
                    });
                });
            }
        }
        init();
    });
}