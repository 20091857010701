import { combineReducers } from "redux";
import searchResult from "./searchReducer";
import eventsListing from "./eventsReducer";
import ocEventsListing from "./ocEventsReducer";

const rootReducer = combineReducers({
    searchResult,
    eventsListing,
    ocEventsListing
});

export default rootReducer;