import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function searchReducer(state = initialState.searchResult, action) {
    switch (action.type) {
        case types.SET_SEARCH_LABEL:
            return {
                ...state,
                searchLabelStore: action.searchLabel
            };
        case types.SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
                isVisiblePagination: action.isVisiblePagination
            };

        case types.SET_INITIAL_DATA:
            return {
                ...state,
                initialData: action.initialData
            };

        case types.SET_CATEGORIES_DATA:
            return {
                ...state,
                categoriesData: action.categoriesData
            };

        case types.SET_DROPDOWN_TYPE:
            return {
                ...state,
                selectValue: action.selectedValue.name,
                selectType: action.selectedValue.type
            };

        case types.SET_CATEGORY:
            return {
                ...state,
                categorySet: action.category,
            };

        case types.SET_SELECTED_CATEGORY:
            return {
                ...state,
                selectedCategory: action.selectedCategory,
            };

        case types.SET_NAVIGATION_DETAILS:
            return {
                ...state,
                numberOfPages: action.numberOfPages,
                selectedPage: action.selectedPage,
                navigation: action.navigation,
            };

        case types.HIDE_PROMOTED:
            return {
                ...state,
                hidePromoted: action.hidePromoted
            };

        case types.SET_SITENAME:
            return {
                ...state,
                siteNameSet: action.sitename
            };

        case types.SET_SITENAME_LABEL:
            return {
                ...state,
                siteNameLabel: action.sitenameLabel
            };

        case types.SET_SHOWSITESFILER:
            return {
                ...state,
                showSitesFilter: action.showSitesFilter
            };

        default:
            return state;
    }
}

