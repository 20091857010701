var $ = require("jquery");

export function shareButton() {
    $(document).ready(function () {
        const popoverContent = $('[data-name="share-button-popover-content"]');
        if (popoverContent && popoverContent.length > 0) {
            const options = {
                html: true,
                content: $('[data-name="share-button-popover-content"]')
            };
            const exampleEl = document.getElementById("share-button");
            const popover = new window.bootstrap.Popover(exampleEl, options);
        }
    });
}
