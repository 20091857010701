import * as bootstrap from 'bootstrap';

var $ = require("jquery");
export function linksTabsWidget() {
    $(document).ready(function () {
        const allPageTabs = $(".section-tabs");
        if (allPageTabs && allPageTabs.length > 0) {
            for (let tabIndex = 0; tabIndex < allPageTabs.length; tabIndex++) {
                const currentTab = $(allPageTabs[tabIndex]);

                const navigationLists = currentTab.find(".navigation.links");

                let navigationTabsId;
                if (navigationLists && navigationLists.length > 0) {
                    for (let i = 0; i < navigationLists.length; i++) {
                        const navigationList = $(navigationLists[i]);
                        const navigationTitle = navigationList.find(".nav-item");
                        const navigationContent = navigationList.find(".tab-pane");
                        const navLinkActive = navigationList.find(".nav-link.active");

                        const navigationTabs = navigationList.find(".nav.nav-tabs");
                        navigationTabsId = $(navigationTabs).attr("id");
                        if (navigationContent && navigationContent.length > 0) {
                            for (let j = 0; j < navigationContent.length; j++) {
                                const navigationItem = $(navigationContent[j]);
                                const navId = $(navigationItem).attr("id");
                                const navAria = $(navigationItem).attr("aria-labelledby");
                                const navIdH = `#${navId}`;

                                const navItem = $(navigationTitle[j]);
                                const navLink = navItem.find(".nav-link");
                                navLink.attr("data-bs-target", navIdH);
                                navLink.attr("aria-controls", navId);
                                navLink.attr("id", navAria);

                                if (navLinkActive.length === 1 && navLinkActive !== navLink) {
                                    navLink.attr("aria-selected", false);
                                }
                                else if (navLinkActive.length === 0) {
                                    if (j === 0) {
                                        navLink.addClass("active");
                                        navigationItem.addClass("show active");
                                    }
                                    else {
                                        navLink.attr("aria-selected", false);
                                    }
                                }
                            }
                        }
                    }
                }
                navigationTabsId = `#${navigationTabsId} button`;
                const triggerTabList = [].slice.call(document.querySelectorAll(navigationTabsId));
                triggerTabList.forEach(function (triggerEl) {
                    var tabTrigger = new bootstrap.Tab(triggerEl);
                    triggerEl.addEventListener("click", function (event) {
                        event.preventDefault();

                        const text = $.trim($(triggerEl).text());
                        if (text) {
                            window.pushGtmEvent(`home-${encodeURIComponent(text.toLowerCase())}`);
                        }

                        tabTrigger.show();
                    });
                });
            }
        }

        function getTabTitle(linkNavContent) {
            if (!linkNavContent) {
                return null;
            }

            const tabPanel = linkNavContent.closest(".tab-pane");
            if (tabPanel && tabPanel.length > 0) {
                const id = tabPanel.attr("id");
                if (id) {
                    const tabContent = tabPanel.parent();
                    if (tabContent) {
                        const navTabs = tabContent.prev();
                        if (navTabs) {
                            var tabButton = navTabs.find(`[aria-controls=${id}]`);
                            if (tabButton) {
                                return encodeURIComponent($.trim(tabButton.text()));
                            }
                        }
                    }
                }
            }
        }

        const allLinkNavContent = $(".link-nav-content a");
        if (allLinkNavContent && allLinkNavContent.length > 0) {
            for (let linkIndex = 0; linkIndex < allLinkNavContent.length; linkIndex++) {
                const tabTitle = getTabTitle($(allLinkNavContent[linkIndex]));
                if (tabTitle) {
                    $(allLinkNavContent[linkIndex]).attr("data-tabtitle", tabTitle);
                }
            }
        }
    });
}