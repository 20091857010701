var $ = require("jquery");
export function accordionWidget() {
    $(document).ready(function () {
        const accordionSections = $(".accordion");
        if (accordionSections && accordionSections.length > 0) {
            accordionSections.each(function () {
                const accordionId = $(this).attr("id");
                if (accordionId) {
                    const panel = $(this).find("div[id*='panel_']");
                    if (panel && panel.length > 0) {
                        panel.attr("data-bs-parent", `#${accordionId}`);
                    }

                    const collapseElementList =
                        [].slice.call(document.getElementById(accordionId).querySelectorAll(".accordion-collapse"));
                    if (collapseElementList && collapseElementList.length > 0) {
                        collapseElementList.map(function(collapseEl) {
                            collapseEl.addEventListener("show.bs.collapse",
                                function() {
                                    $(collapseEl).parent().addClass("open");
                                });
                            collapseEl.addEventListener('hide.bs.collapse',
                                function() {
                                    $(collapseEl).parent().removeClass("open");
                                });

                            collapseEl.addEventListener('shown.bs.collapse', function (e) {
                                const $card = $(this).closest(".accordion-item");
                                const $open = $($(this).attr("data-bs-parent")).find(".collapse.show");

                                const prevAll = $card.prevAll();
                                const closestAccordionItem = $open.closest(".accordion-item");
                                const length = prevAll.filter(closestAccordionItem).length;

                                var additionalOffset = 0;
                                if (length !== 0) {
                                    additionalOffset = $open.height();
                                }

                                const headerInside = document.getElementById("header-inside");
                                if (headerInside != null && headerInside.classList.contains("fixed-top")) {
                                    additionalOffset += 210;
                                }
                                else {
                                    additionalOffset += 80;
                                }

                                const top = window.pageYOffset || document.documentElement.scrollTop;
                                const openTop = $open.offset().top;

                                if (top + 193 > openTop) {
                                    window.onscroll = null;
                                    $("html,body").animate({
                                        scrollTop: $card.offset().top - additionalOffset
                                    }, 100, function () {
                                        setTimeout(function () {
                                            window.onscroll = function () { window.onScrollHandler() };
                                        }, 1000);
                                    });
                                }
                            });
                            return new window.bootstrap.Collapse(collapseEl,
                                { parent: document.getElementById(accordionId), toggle: false });
                        });
                    }
                }
            });
        }
    });
}