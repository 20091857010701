import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function ocEventsReducer(state = initialState.ocEventsListing, action) {

    switch (action.type) {
       
        default:
            return state;
    }
}

