import * as React from "react";
import { Suspense, lazy } from "react";
import * as ReactDOM from "react-dom";
import * as ReactDOMClient from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";

import * as bootstrap from "bootstrap";
import configureStore from "./redux/configureStore";
import LoadingIndicator from "./shared/LoadingIndicator";
const HomePage = lazy(() => import("./pages/homePage"));
const Navbar = lazy(() => import("./navbar/navbar"));
const Search = lazy(() => import("./search/search"));
const SearchMobile = lazy(() => import("./search/searchMobile"));
const SearchPage = lazy(() => import("./search/searchPage"));
const SideSearchCategoryPage = lazy(() => import("./search/sideSearchCategoryPage"));
const MobileNavbar = lazy(() => import("./navbar/mobileNavbar"));
const FormComponent = lazy(() => import("./formBuilder/formComponent"));
const Events = lazy(() => import("./events/events"));
const EventsFilters = lazy(() => import("./events/eventsFilters"));
const News = lazy(() => import("./news/newsListView"));
import Autosuggest from "./search/ReactAutosuggest/Autosuggest";
const Parks = lazy(() => import("./parks/parks"));
const OakvilleCentreEvents = lazy(() => import("./oakvilleCentreEvents/oakvilleCentreEvents"));
const OakvilleCentreEventsFilters = lazy(() => import("./oakvilleCentreEvents/oakvilleCentreEventsFilters"));
const NewsNotifications = lazy(() => import("./newsNotifications/newsNotifications"));

import { siteInit } from "./site/index";

const store = configureStore();
const globalAny: any = global;

globalAny.React = React;
globalAny.ReactDOM = ReactDOM;
globalAny.ReactDOMClient = ReactDOMClient;

globalAny.HomePage = (props: any) => (<Suspense fallback={<div />}><HomePage {...props} /></Suspense>);
globalAny.Navbar = (props: any) => (<Suspense fallback={<div />}><Navbar {...props} /></Suspense>);
globalAny.MobileNavbar = (props: any) => (<Suspense fallback={<div />}><MobileNavbar {...props} /></Suspense>);
globalAny.Search = (props: any) => (<Suspense fallback={<div />}><ReduxProvider store={store}><Search {...props} /></ReduxProvider></Suspense>);
globalAny.SearchMobile = (props: any) => (<Suspense fallback={<div />}><ReduxProvider store={store}><SearchMobile {...props} /></ReduxProvider></Suspense>);
globalAny.SearchPage = (props: any) => (<Suspense fallback={<div />}><ReduxProvider store={store}><SearchPage {...props} /></ReduxProvider></Suspense>);
globalAny.SideSearchCategoryPage = (props: any) => (<Suspense fallback={<div />}><ReduxProvider store={store}><SideSearchCategoryPage {...props} /></ReduxProvider></Suspense>);
globalAny.FormComponent = (props: any) => (<Suspense fallback={<div />}><FormComponent {...props} /></Suspense>);
globalAny.Events = (props: any) => (<Suspense fallback={<div />}><ReduxProvider store={store}><Events {...props} /></ReduxProvider></Suspense>);
globalAny.EventsFilters = (props: any) => (<Suspense fallback={<div />}><ReduxProvider store={store}><EventsFilters {...props} /></ReduxProvider></Suspense>);
globalAny.Autosuggest = (props: any) => (<Suspense fallback={<div />}><Autosuggest {...props} /></Suspense>);
globalAny.Parks = (props: any) => (<Suspense fallback={<div />}><Parks {...props} /></Suspense>);
globalAny.OakvilleCentreEvents = (props: any) => (<Suspense fallback={<div />}><ReduxProvider store={store}><OakvilleCentreEvents {...props} /></ReduxProvider></Suspense>);
globalAny.OakvilleCentreEventsFilters = (props: any) => (<Suspense fallback={<div />}><ReduxProvider store={store}><OakvilleCentreEventsFilters {...props} /></ReduxProvider></Suspense>);
globalAny.NewsNotifications = (props: any) => (<Suspense fallback={<div />}><NewsNotifications {...props} /></Suspense>);
globalAny.News = (props: any) => (<Suspense fallback={<div />}><ReduxProvider store={store}><News {...props} /></ReduxProvider></Suspense>);

globalAny.bootstrap = bootstrap;

siteInit();

export default global;