const initialState = {
    searchResult: {
        searchLabelStore: "",
        isLoading: false,
        isVisiblePagination: false,
        initialData: null,
        isLoadingInitialData: false,
        categoriesData: [],
        hidePromoted: false,
        selectedCategory: { total: 0, items: [] },
        selectValue: "All Results Type",
        selectType: "",
        categorySet: "all",
        siteNameLabel: "All Sites",
        siteNameSet: "",
        numberOfPages: 0,
        selectedPage: 1,
        navigation: null,
        showSitesFilter: false
    },
    eventsListing: {
        
        viewMode: "calendar",
        calendarEvents: {
            fiters: {
                startDate: null,
                endDate: null
            },
            items: [],
            recurringEvents: [],
            totalItems: 0,
            isLoading: false,
        },
        listEvents: {
            filters: {
                startDate: null,
                endDate: null,
                searchKeywords: "",
            },
            items: [],
            totalItems: 0,
            pageIndex: 0,
            eventsPerPage: 10,
            count: 0,
            isLoading: false,
        }
    },
    ocEventsListing: {
        items: [],
        tags: [],
    },
};

export default initialState;