var $ = require("jquery");

export function tabsWidget() {
    $(document).ready(function() {
        const navigationLists = $(".navigation");
        if (navigationLists && navigationLists.length > 0) {
            for (let i = 0; i < navigationLists.length; i++) {
                const navigationList = $(navigationLists[i]);
                const navigationItems = navigationList.find(".nav-items");
                const navigationTabs = navigationList.find(".nav.nav-tabs");
                const navigationContents = navigationList.find(".tab-content");
                
                if (navigationItems && navigationItems.length > 0) {
                    for (let j = 0; j < navigationItems.length; j++) {
                        const navigationItem = $(navigationItems[j]);
                        const navItem = navigationItem.find(".nav-item");
                        const navLink = navigationItem.find(".nav-link");
                        const tabPane = navigationItem.find(".tab-pane");

                        if (j === 0) {
                            navLink.addClass("active");
                            tabPane.addClass("show active");
                        }
                        
                        if (tabPane && tabPane.length > 0) {
                            navigationContents.append(tabPane[0].outerHTML);
                        }

                        if (navItem && navItem.length > 0) {
                            navigationTabs.append(navItem[0].outerHTML);
                        }
                    }
                    navigationItems.remove();
                }
            }
        }            
    });
}
