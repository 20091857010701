import * as bootstrap from 'bootstrap';

var $ = require("jquery");
export function simpleTabsWidget() {
    $(document).ready(function () {
        const allPageTabs = $(".section-simple-tabs");
        if (allPageTabs && allPageTabs.length > 0) {
            for (let tabIndex = 0; tabIndex < allPageTabs.length; tabIndex++) {
                const currentTab = $(allPageTabs[tabIndex]);

                const navigationLists = currentTab.find(".navigation.links");

                let navigationTabsId;
                if (navigationLists && navigationLists.length > 0) {
                    for (let i = 0; i < navigationLists.length; i++) {
                        const navigationList = $(navigationLists[i]);
                        const navigationTitle = navigationList.find(".nav-item");
                        const navigationContent = navigationList.find(".tab-pane");
                        const navLinkActive = navigationList.find(".nav-link.active");

                        const navigationTabs = navigationList.find(".nav.nav-tabs");
                        navigationTabsId = $(navigationTabs).attr("id");
                        if (navigationContent && navigationContent.length > 0) {
                            for (let j = 0; j < navigationContent.length; j++) {
                                const navigationItem = $(navigationContent[j]);
                                const navId = $(navigationItem).attr("id");
                                const navAria = $(navigationItem).attr("aria-labelledby");
                                const navIdH = `#${navId}`;

                                const navItem = $(navigationTitle[j]);
                                const navLink = navItem.find(".nav-link");
                                navLink.attr("data-bs-target", navIdH);
                                navLink.attr("aria-controls", navId);
                                navLink.attr("id", navAria);

                                if (navLinkActive.length === 1 && navLinkActive !== navLink) {
                                    navLink.attr("aria-selected", false);
                                }
                                else if (navLinkActive.length === 0) {
                                    if (j === 0) {
                                        navLink.addClass("active");
                                        navigationItem.addClass("show active");
                                    }
                                    else {
                                        navLink.attr("aria-selected", false);
                                    }
                                }
                            }
                        }
                    }
                }
                navigationTabsId = `#${navigationTabsId} button`;
                const triggerTabList = [].slice.call(document.querySelectorAll(navigationTabsId));
                triggerTabList.forEach(function (triggerEl) {
                    var tabTrigger = new bootstrap.Tab(triggerEl);
                    triggerEl.addEventListener("click", function (event) {
                        event.preventDefault();
                        tabTrigger.show();
                        const iframe = $("iframe")[0];
                        $(iframe).attr("src", $(iframe).attr("src"));
                    });
                });
            }
        }
    });
}