export const SET_SEARCH_LABEL = "SET_SEARCH_LABEL";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_INITIAL_DATA = "SET_INITIAL_DATA";
export const SET_CATEGORIES_DATA = "SET_CATEGORIES_DATA";
export const SET_DROPDOWN_TYPE = "SET_DROPDOWN_TYPE";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const HIDE_PROMOTED = "HIDE_PROMOTED";
export const SET_SITENAME = "SET_SITENAME";
export const SET_SHOWSITESFILER = "SET_SHOWSITESFILER";
export const SET_SITENAME_LABEL = "SET_SITENAME_LABEL";

export const EVENTS_FETCH_BEGIN = "EVENTS_FETCH_BEGIN";
export const EVENTS_FETCH_SUCCESS = "EVENTS_FETCH_SUCCESS";
export const EVENTS_SWITCH_VIEW_MODE = "EVENTS_SWITCH_VIEW_MODE";
export const IS_LOADING_LIST_EVENTS = "IS_LOADING_LIST_EVENTS";
export const SET_LIST_EVENTS_ITEMS = "SET_LIST_EVENTS_ITEMS";
export const SET_LIST_EVENTS_FILTERS_DATES = "SET_LIST_EVENTS_FILTERS_DATES";
export const SET_LIST_EVENTS_FILTERS_SEARCH_KEYWORDS = "SET_LIST_EVENTS_FILTERS_SEARCH_KEYWORDS";
export const SET_LIST_EVENTS_COUNT_SEARCH = "SET_LIST_EVENTS_COUNT_SEARCH";
export const LOAD_MORE_LIST_EVENTS_ITEMS = "LOAD_MORE_LIST_EVENTS_ITEMS";

export const SET_NAVIGATION_DETAILS = "SET_NAVIGATION_DETAILS";
