const $ = require("jquery");
window.jQuery = $;
window.$ = $;

const viewport = require("../../3rdParty/responsiveBootstrapToolkit");
window.viewport = viewport;

import { swiffyslider } from "swiffy-slider";
window.swiffyslider = swiffyslider;

window.Splide = require("../../3rdParty/splide.min");

const lity = require("../../3rdParty/lity.min");
window.lity = lity;

const highcharts = require("../../3rdParty/highcharts/highcharts");
window.Highcharts = highcharts;

require("../../3rdParty/highcharts/oakville");

window.PhotoSwipe = require("./photoswipe/photoswipe");
window.PhotoSwipeUI_Default = require("./photoswipe/photoswipe-ui-default");

import { general } from "./general";
import { featuredItemsCarouselWidget } from "./featuredItemsCarouselWidget";
import { carouselWidget } from "./carouselWidget";
import { tabsWidget } from "./tabsWidget";
import { linksTabsWidget } from "./linksTabsWidget";
import { accordionWidget } from "./accordionWidget";
import { navSideNavigation } from "./navSideNavigation";
import { highChart } from "./highChart";
import { photoSwipe } from "./photoSwipe";
import { shareButton } from "./shareButton";
import { featuredEventsCarouselWidget } from "./featuredEventsCarouselWidget";
import { accordionPoliciesWidget } from "./accordionPoliciesWidget";
import { accordionBylawsWidget } from "./accordionBylawsWidget";
import { simpleTabsWidget } from "./simpleTabsWidget";
import { GoogleTranslateWidget } from "./googleTranslateWidget";
import { autocomplete } from "./jquery.autocomplete"; // Please leave in as it's referenced in the general.js file.

window.pushGtmEvent = (eventName) => {
    if (window.dataLayer) {
        var eventToPush = {
            'event': eventName
        };

        console.log(eventToPush);
        window.dataLayer.push(eventToPush);
    }
}

export function siteInit() {
    general();
    featuredItemsCarouselWidget();
    carouselWidget();
    tabsWidget();
    linksTabsWidget();
    accordionWidget();
    navSideNavigation();
    highChart();
    photoSwipe();
    shareButton();
    featuredEventsCarouselWidget();
    accordionPoliciesWidget();
    accordionBylawsWidget();
    simpleTabsWidget();
    GoogleTranslateWidget();
}