Highcharts.theme = {
	colors: ["#2a7cb1" , "#ff3ede" , "#5cb85c" , "#f2b705" , "#f27405" , "#9234db" , "#a17f4f" , "#379a37" , "#f24040" , "#7a7a7a", "#cccccc"],
	chart: {
		backgroundColor: null,
		style: {
			fontFamily: "Raleway, sans-serif"
		}
	},
	title: {
		style: {
			fontSize: '22px',
			fontWeight: '300'
		}
	},
	tooltip: {
		borderWidth: 0,
		backgroundColor: 'rgba(255,255,255,0.9)',
		shadow: false
	},
	legend: {
		itemStyle: {
			fontWeight: '300',
			fontSize: '15px'
		}
	},
	xAxis: {
		gridLineWidth: 1,
		labels: {
			style: {
				fontSize: '15px'
			}
		}
	},
	yAxis: {
		minorTickInterval: 'auto',
		labels: {
			style: {
				fontSize: '15px'
			}
		}
	},
	plotOptions: {
		candlestick: {
			lineColor: '#333333'
		}
	},


	// General
	background2: 'transparent'

};

// Apply the theme
Highcharts.setOptions(Highcharts.theme);
