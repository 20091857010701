var $ = require("jquery");

export function general() {
    $(document).ready(function () {
        $("img").not(".alert-icon").each(function () {
            if (!$(this).hasClass("img-fluid")) {
                $(this).addClass("img-fluid");
            }
        });
    });

    $(document).ready(function () {
        $("table").each(function () {
            $(this).wrap('<div class="table-responsive">');
        });
    });

    $(document).ready(function () {
        $('#skip-nav').click(function (event) {
            var hash = window.location.hash;
            event.preventDefault();
            hash += "#main-content";
            window.location = hash;
            var x = window.pageXOffset,
                y = window.pageYOffset;
            $(window).one('scroll', function () {
                window.scrollTo(x, y);
            })
        });
        $('a[href*="#"]').on('click', (event) => {
            const headerInside = document.getElementById("header-inside");
            let defaultHeight = 100;
            if (headerInside !== null) {
                defaultHeight = headerInside.offsetHeight;
            }
            const hash = event.currentTarget.hash;
            const hash2 = hash.replace("#", "");
            var anchor = document.getElementById(hash2);
            if (hash && anchor) {
                event.preventDefault();
                $('html, body').animate({ scrollTop: $(hash).offset().top - defaultHeight }, 50);
                var newUrl = window.location.href.split("#")[0] + hash;
                history.pushState({}, null, newUrl);
            }
        });
    });


    $(document).ready(function () {
        $('.navbar-toggler').click(function (event) {
            $('button[id^="mobile-search-bar"]').each(function () {
                if ($(this).hasClass("show")) {
                    $(this).click();
                }
            });
        });
    });


    $(window).bind("load", function () {
        var hashByUrl = document.URL.substr(document.URL.indexOf('#') + 1);
        let hash = document.URL.substring(document.URL.indexOf('#'))
        if (hashByUrl.length > 0) {
            const headerInside = document.getElementById("header-inside");
            let defaultHeight = 100;
            if (headerInside !== null) {
                defaultHeight = headerInside.offsetHeight;
            }
            var anchor = document.getElementById(hashByUrl);
            if (anchor) {
                event.preventDefault();
                $('html, body').animate({ scrollTop: $(hash).offset().top - defaultHeight }, 50);
            }
        }
    });

    $(document).ready(function () {
        $(".table-responsive").each(function (index) {
            const uniqueID = "table-responsive-" + index;
            $(this).attr("id", uniqueID);

            window.addEventListener("resize", function () {
                var tableResponsive = $("#" + uniqueID);
                if (tableResponsive && tableResponsive.length > 0) {
                    if (tableResponsive[0].scrollWidth > tableResponsive.width()) {
                        tableResponsive.addClass("shadow-right");
                    }
                    else if (tableResponsive[0].scrollWidth <= tableResponsive.width()) {
                        tableResponsive.removeClass("shadow-right");
                    }
                }
            });

            $("#" + uniqueID).on("scroll", function () {
                const cur = $(this).scrollLeft();
                if (cur === 0) {
                    $("#" + uniqueID + " .sticky-first-column").addClass("shadow-right").removeClass("shadow-left");
                }
                else {
                    const max = $(this)[0].scrollWidth - $(this).width();
                    if (Math.round(cur) >= Math.round(max)) {
                        $("#" + uniqueID + " .sticky-first-column").addClass("shadow-left").removeClass("shadow-right");
                    } else {
                        $("#" + uniqueID + " .sticky-first-column").addClass("shadow-right shadow-left");
                    }
                }
            });

            $("#" + uniqueID).trigger("scroll");
        });
    });

    $(document).ready(function () {
        $("a").each(function () {
            const currentHref = $(this).attr("href");
            if (currentHref === "/") {
                $(this).addClass("disabled");
            }
        });
    });

    $(document).ready(function () {
        $(".goog-te-gadget-icon").attr("alt", "Google-Translate");
    });

    $(document).ready(function () {
        $(".goog-te-menu-value img").attr("alt", "icon");
    });


    $(document).ready(function () {
        $(".btn-close-alert").click(function () {
            const id = $(this).closest("[data-alert-id]").data("alert-id");
            const days = $(this).closest("[data-cookie-duration]").data("cookie-duration");
            const myDate = new Date();
            myDate.setTime(myDate.getTime() + (days * 24 * 60 * 60 * 1000));
            document.cookie = `alert-${id}=${id}; expires=${myDate.toGMTString()}; Secure; path=/`;

            setTimeout(function () {
                const alertSticky = document.getElementById("alert-sticky");
                var alertOffset = 0;
                if (alertSticky !== null) {
                    alertOffset = alertSticky.offsetHeight;
                }
                const headerInside = document.getElementById("header-inside");
                if (headerInside !== null) {
                    headerInside.style.position = "sticky";
                    headerInside.style.top = alertOffset + "px";
                }

                // 
                recalcStickyTop();
            },
                300);
        });

        $(".btn-close-modal").click(function () {
            const id = $(this).closest("[data-modal-id]").data("modal-id");
            const days = $(this).closest("[data-cookie-duration]").data("cookie-duration");
            const myDate = new Date();
            myDate.setTime(myDate.getTime() + (days * 24 * 60 * 60 * 1000));
            document.cookie = `alert-${id}=${id}; expires=${myDate.toGMTString()}; Secure; path=/`;

            recalcStickyTop();
        });

        const modals = $(".modal-alert");
        if (modals && modals.length > 0) {
            for (let i = 0; i < modals.length; i++) {
                $(modals[i]).modal("show");
            }
        }
    });

    $(document).ready(function () {
        $("header .sign-in").click(function () {
            document.cookie = `salesforceRedirectUrl=${window.location.href}; Secure; path=/`;
        });

        $("header .signed-in-container").hover(function () {
            console.log('Signed In Hover In');
            $(this).find(".dropdown-menu").show();
        }, function () {
            console.log('Signed In Hover Out');
            $(this).find(".dropdown-menu").hide();
        });
        $("header .sign-out").click(function () {
            document.cookie = `salesforceLoggedOutUrl=${window.location.href}; Secure; path=/`;
        });
    });

    $(document).ready(function () {
        $('#searchInMyNeighbourhoodButton').click(function (e) {
            searchAddress(e);
        })
        $('#searchInMyNeighbourhoodField').keypress(function (e) {
            const keycode = e.keyCode || e.which;
            if (keycode == '13') {
                searchAddress(e);
            }
        });
        $('#searchInMyNeighbourhoodField').autocomplete({
            minChars: 3,
            serviceUrl: '/api/WardAutoComplete/search/',
            paramName: 'address',
            onSelect: function (suggestion) {
                searchAddress(null);
            }
        });

        function searchAddress(e) {
            $('#searchInMyNeighbourhoodNotFound').text($('#searchInMyNeighbourhoodNotFound').data('loading-text'));
            if (e)
                e.preventDefault();
            const searchText = $('#searchInMyNeighbourhoodField').val();
            $.ajax({
                type: "POST",
                url: "/api/wardlookup/search/",
                data: JSON.stringify({ "SearchAddress": searchText }),
                success: function (data) {
                    if (data && data.redirectUrl) {
                        try {
                            window.dataLayer = window.dataLayer || [];
                            var gaPayload = { 'event': 'neighbourhood', 'ward': data.wardId }
                            window.dataLayer.push(gaPayload);
                        } catch (e) {
                            console.log('GA Event Failed', e)
                        }
                        window.location.href = data.redirectUrl;
                    } else {
                        $('#searchInMyNeighbourhoodNotFound').text($('#searchInMyNeighbourhoodNotFound').data('not-found-text'));
                    }
                },
                contentType: "application/json"
            });
        }
    });

    const closeButton = document.querySelector('input[name="AllowAll"]');
    if (closeButton) {
        closeButton.addEventListener('click', function () {



            const cookieNotice = document.querySelector('.cookie-notice');
            if (cookieNotice) {
                cookieNotice.style.display = 'none';


                const myDate = new Date();
                myDate.setTime(myDate.getTime() + (100 * 24 * 60 * 60 * 1000));
                document.cookie = "cookie_consent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; Secure; path=/";
                //

                recalcStickyTop();
            }
        });
    }

    document.addEventListener("DOMContentLoaded", function () {
        var pageSections = document.getElementsByClassName("page-section");

        for (var i = 0; i < pageSections.length; i++) {
            var jumplinksNav = pageSections[i].querySelector(".jumplinks-nav");
            if (jumplinksNav) {
                //pageSections[i].classList.add("sticky");
            }
        }
    });

    function recalcStickyTop() {

        /*setTimeout(function () {
            var top = $('header').height() + (jQuery(".alert-sticky").height() || 0);
            
            jQuery(".sticky").css("top", top + 'px');
            console.log('recalcStickyTop= ' + top);
        }, 1000);*/


        //jQuery(".sticky").css("top", $('header').height() + 'px');
        //console.log('recalcStickyTop= ' + top);
    }

    $(document).ready(function () {

        recalcStickyTop();

        var jumpLinksNav = jQuery(".jumplinks-nav").first();
        if (jumpLinksNav.length) {
            var ulContainer = jumpLinksNav.find("ul.jumplink:first");
            var mobileContainer = jumpLinksNav.find(".mobile-jumplink .dropdown-menu ul:first");
            $("[data-jumplink-label]").each(function (index) {
                var id = $(this).attr("id");
                if ((id || "").length) {

                    var title = $(this).data('jumplink-label').trim();

                    var jumplinkLi = $('<li>');
                    ulContainer.append(jumplinkLi);
                    var jumplinkA = $('<a class="link">');
                    jumplinkLi.append(jumplinkA);
                    jumplinkA.prop('href', '#' + id);

                    jumplinkA.text(title.toUpperCase() || id.toUpperCase());

                    var jumplinkLiM = jumplinkLi.clone();
                    mobileContainer.append(jumplinkLiM);
                }
            })

            jumpLinksNav.show();

            var childToMoveLast = $(".available-in-estore", ulContainer).remove();
            ulContainer.append(childToMoveLast);

            var childToMoveLastMobile = $(".available-in-estore", mobileContainer).remove();
            mobileContainer.append(childToMoveLastMobile);
        }

        var page = $("html, body");

        if (jQuery(".jumplink").length) {
            var $jumplinks = jQuery(".jumplink a");
            var $mobileJumplinks = jQuery(".mobile-jumplink a");

            function handleJumplinkClick(e) {
                var targetRef = (jQuery(this).attr("href") || "").trim();
                if (targetRef.length < 2 || targetRef.indexOf("#") != 0) return;

                var targetEl = jQuery(targetRef);
                if (!targetEl.length) return;

                e.preventDefault();
                page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function () {
                    page.stop();
                });

                var jumplinkHeight;
                var alertHeight = jQuery(".alert-sticky").height() || 0;

                if (jQuery(".jumplink").is(":visible")) {
                    jumplinkHeight = 200;
                } else if (jQuery(window).width() <= 767) {
                    jumplinkHeight = 60;
                } else {
                    jumplinkHeight = jQuery(".jumplink").outerHeight();
                }

                page.animate({
                    scrollTop: targetEl.offset().top - jumplinkHeight - alertHeight
                }, "slow",
                    function () {
                        page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
                    }
                );
            }

            $jumplinks.click(handleJumplinkClick);
            $mobileJumplinks.click(handleJumplinkClick);

            jQuery(".mobile-jumplink button").click(function () {
                jQuery(".mobile-jumplink .dropdown-menu").toggle();
            });

            $mobileJumplinks.click(function () {
                jQuery(".mobile-jumplink .dropdown-menu").hide();
            });
            var jumplinkscrollTimer = undefined;
            var $mobileJumplink = jQuery(".mobile-jumplink button");

            window.addEventListener("scroll", function (event) {
                clearTimeout(jumplinkscrollTimer);
                jumplinkscrollTimer = setTimeout(function () {
                    var isMobile = jQuery(".mobile-jumplink").is(":visible");

                    var jumplinkHeight = isMobile ? jQuery(".mobile-jumplink").outerHeight() : jQuery(".jumplink").outerHeight();
                    var fromTop = window.scrollY + jumplinkHeight + 200;
                    var isBottom = ($(window).scrollTop() + $(window).height() == $(document).height());

                    var showDefaultText = true;

                    $jumplinks.toArray().forEach(function (link, index) {
                        var section = document.querySelector(link.hash);
                        var isLast = index == $jumplinks.length - 1;

                        var alertHeight = 0;
                        var alertElement = jQuery(".alert-sticky");
                        if (alertElement.is(":visible")) {
                            alertHeight = alertElement.outerHeight();
                        }

                        var fromTopWithAlert = fromTop + alertHeight;

                        if (
                            (section.offsetTop <= fromTopWithAlert || isBottom && isLast) &&
                            section.offsetTop + section.offsetHeight > fromTopWithAlert
                        ) {

                            if (isMobile) {
                                $mobileJumplink.text(link.textContent.trim());
                                showDefaultText = false;
                            }

                            link.classList.add("active");

                        } else {
                            link.classList.remove("active");
                        }
                    });

                    if (showDefaultText) {
                    }
                }, 200);
            });
        }

        $(".stop-propagation").click(function (e) {
            e.stopPropagation();
        });
    });
};

window.onscroll = function () { window.onScrollHandler() };

var navbar = document.getElementById("nav_bar");
var alert = document.getElementById("alert");
var navbarInside = document.getElementById("nav_bar_inside");
var headerInside = document.getElementById("header-inside");
var headerHome = document.getElementById("header-home");
var navbarHomeTop = document.getElementById("navbar-home-top");
var navbarHomeMiddle = document.getElementById("navbar-home-middle");
var alertSticky = document.getElementById("alert-sticky");

if (navbar !== null) {
    var sticky = navbar.offsetTop;
}
if (navbarInside !== null) {
    var stickyInside = navbarInside.offsetTop;
}

let oldValue = 0;
let oldValueHome = 0;

window.onScrollHandler = function () {

    var alertOffset = 0;
    if (alertSticky !== null) {
        alertOffset = alertSticky.offsetHeight;
    }

    if (headerInside != null) {
        const newValue = window.pageYOffset;
        if (oldValue - newValue < 0 && window.pageYOffset > 5) {
            if (alertOffset > 0) {
                headerInside.style.position = "sticky";
                headerInside.style.top = alertOffset + "px";

            }
            headerInside.classList.add("fixed-top");
            if (alert) {
                alert.classList.add("alert-fixed");
            }
        }
        else if (window.pageYOffset === 0) {
            headerInside.classList.remove("fixed-top");
            if (alert) {
                alert.classList.remove("alert-fixed");
            }
        }
        oldValue = newValue;
    }

    if (headerHome != null) {
        if (window.pageYOffset >= sticky) {
            navbarHomeTop.classList.add("d-block", "d-none");
            navbarHomeMiddle.classList.remove("d-block", "d-none");
            headerHome.classList.add("fixed-top");
            if (alert) {
                alert.classList.add("alert-fixed");
            }
        }
        if (window.pageYOffset === 0) {
            navbarHomeMiddle.classList.add("d-block", "d-none");
            navbarHomeTop.classList.remove("d-block", "d-none");
            headerHome.classList.remove("fixed-top");
            if (alert) {
                alert.classList.remove("alert-fixed");
            }
        }
    }
};